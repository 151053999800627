import styles from './StatisticsItem.module.scss';

const StatisticsItem = ({ name, value, index }) => {
  return (
    <div
      className={`${styles.item_container} ${
        styles[`item_${index}`]
      } text-white text-3xl gap-10 w-full`}>
      <span>{name}</span>
      <div className={styles.line} />
      <span className="text-center">{value}</span>
    </div>
  );
};

export default StatisticsItem;
