import ProjectCard from '../ProjectCard/ProjectCard';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Pagination } from 'swiper';
import './ProjectCardsContainer.scss';

import 'swiper/scss';
import 'swiper/scss/pagination';

const ProjectCardsContainer = ({ portfolioCards }) => {
  return (
    <Swiper pagination={{ clickable: true }} modules={[Pagination]}>
      {portfolioCards.map((card) => (
        <SwiperSlide key={card._uid}>
          <ProjectCard title={card.title} description={card.description} image={card.image} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ProjectCardsContainer;
