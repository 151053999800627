import { useStoryblok, StoryblokComponent } from '@storyblok/react';
import { useEffect } from 'react';
import styles from './App.module.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';

const App = () => {
  let slug = window.location.pathname === '/' ? 'home' : window.location.pathname.replace('/', '');

  useEffect(() => {
    AOS.init();
  }, []);

  const story = useStoryblok(slug, { version: 'draft' });
  if (!story || !story.content) {
    return <div className={styles.loading}>Loading...</div>;
  }

  return <StoryblokComponent blok={story.content} />;
};
export default App;
