import styles from './DesktopNavigation.module.scss';
import { scrollTo } from '../../../utils/scrollTo';
import AuthorLogo from '../../UI/AuthorLogo/AuthorLogo';

const DesktopNavigation = () => {
  return (
    <header className="container mx-auto flex flex-wrap justify-around items-center p-8 text-white text-lg">
      <AuthorLogo />
      <nav className="my-4 lg:my-0">
        <ul className="flex flex-wrap gap-x-8">
          <li className={styles.nav_item} onClick={() => scrollTo('services')}>
            Services
          </li>
          <li className={styles.nav_item} onClick={() => scrollTo('about')}>
            About
          </li>
          <li className={styles.nav_item} onClick={() => scrollTo('contact')}>
            Contact Me
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default DesktopNavigation;
