import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './Timeline.scss';

const Timeline = ({ timeline }) => {
  return (
    <VerticalTimeline animate={false}>
      {timeline.map((element) => {
        return (
          <VerticalTimelineElement
            key={element._uid}
            className="vertical-timeline-element--work"
            contentArrowStyle={{ borderRight: '7px solid  #00000033' }}
            date={element.date}
            icon={<element.icon />}
            iconStyle={{
              backgroundImage:
                'linear-gradient(to right, #462523 0, #cb9b51 22%, #f6e27a 45%, #f6f2c0 50%, #f6e27a 55%, #cb9b51 78%, #462523 100%)'
            }}>
            <h3 className="vertical-timeline-element-title">{element.position}</h3>
            <h3 className="vertical-timeline-element-title">{element.company}</h3>
            <h4 className="vertical-timeline-element-subtitle">{element.location}</h4>
            <p>{element.description}</p>
          </VerticalTimelineElement>
        );
      })}
    </VerticalTimeline>
  );
};

export default Timeline;
