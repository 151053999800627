import SectionSubTitle from '../SectionSubTitle/SectionSubTitle';
import SectionTitle from '../SectionTitle/SectionTitle';
import styles from './SectionHeadline.module.scss';

const SectionHeadline = ({ title, subTitle, lineHeight }) => {
  return (
    <div className={`text-center sm:text-left lg:px-32 xl:px-64 ${styles.text}`}>
      <SectionTitle title={title} lineHeight={lineHeight} />
      <SectionSubTitle subTitle={subTitle} />
    </div>
  );
};

export default SectionHeadline;
