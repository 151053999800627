import StatisticsItem from '../StatisticsItem/StatisticsItem';
import styles from './Statistics.module.scss';

const Statistics = ({ statisticsItems }) => {
  return (
    <div className={`text-white text-5xl gap-10 ${styles.statistics_container}`}>
      {statisticsItems.map((item, index) => {
        return <StatisticsItem key={item._uid} name={item.name} value={item.value} index={index} />;
      })}
    </div>
  );
};

export default Statistics;
