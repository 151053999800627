import classNames from 'classnames';
import { UilLinkedin, UilGithub } from '@iconscout/react-unicons';

const SocialMedia = ({ direction, linkedInLink, githubLink }) => {
  return (
    <div
      className={classNames('flex text-white text-2xl', {
        'flex-col': direction === 'column',
        'flex-row justify-center': direction === 'row'
      })}>
      <a
        href={linkedInLink}
        className={classNames({
          'mb-8': direction === 'column',
          'mr-8': direction === 'row'
        })}
        aria-label="LinkedIn">
        <UilLinkedin />
      </a>
      <a href={githubLink} aria-label="Github">
        <UilGithub />
      </a>
    </div>
  );
};

export default SocialMedia;
