import InProgress from '../../../UI/InProgress/InProgress';
import styles from './ContactForm.module.scss';

const ContactForm = ({
  onTextFieldChange,
  onSubmit,
  values,
  errors,
  loading,
  submitError,
  success
}) => {
  return (
    <form onSubmit={onSubmit}>
      <div className="flex flex-col sm:flex-row mb-4">
        <fieldset className="mr-4 w-full mb-4 sm:mb-0 text-white">
          <input
            type="text"
            id="name"
            className={`text-2xl w-full bg-transparent ${styles.input}`}
            placeholder="Name"
            value={values.name}
            onChange={(e) => onTextFieldChange('name', e.target.value)}
          />
          {errors.name && <p>{errors.name}</p>}
        </fieldset>

        <fieldset className="w-full text-white">
          <input
            type="email"
            id="email"
            className={`text-2xl bg-transparent w-full ${styles.input}`}
            placeholder="Email"
            value={values.email}
            onChange={(e) => onTextFieldChange('email', e.target.value)}
          />
          {errors.email && <p>{errors.email}</p>}
        </fieldset>
      </div>

      <fieldset className="text-white mb-4">
        <textarea
          type="text"
          id="message"
          className={`w-full text-2xl bg-transparent  ${styles.input}`}
          rows="7"
          placeholder="Message..."
          value={values.message}
          onChange={(e) => onTextFieldChange('message', e.target.value)}></textarea>
        {errors.message && <p>{errors.message}</p>}
      </fieldset>

      {submitError && !loading && !success && (
        <p className="text-white mb-4">Something went wrong, please try again later.</p>
      )}

      {!submitError && !loading && success && (
        <p className="text-white mb-4">Message sent succesfully, I will reach out you soon!</p>
      )}

      <div className="flex justify-end">
        <button
          type="submit"
          disabled={loading}
          className={`${styles.submit_button} rounded-full font-bold text-2xl z-10`}>
          {loading ? <InProgress /> : 'Send'}
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
