import { elastic as Menu } from 'react-burger-menu';
import styles from './MobileNavigation.module.scss';
import Burger from '@animated-burgers/burger-squeeze';
import '@animated-burgers/burger-squeeze/dist/styles.css';
import { useState } from 'react';
import { scrollTo } from '../../../utils/scrollTo';

const MobileNavigation = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const isMenuOpen = (state) => {
    setMenuOpen(state.isOpen);
  };

  const scrollToSection = (id) => {
    scrollTo(id);
    setMenuOpen(false);
  };

  return (
    <Menu
      isOpen={menuOpen}
      customBurgerIcon={<Burger isOpen={menuOpen} />}
      onStateChange={isMenuOpen}
      burgerButtonClassName={styles.bm_burger_button}
      burgerBarClassName={styles.bm_burger_bars}
      crossButtonClassName={styles.bm_cross_button}
      crossClassName={styles.bm_cross}
      menuClassName={styles.bm_menu}
      morphShapeClassName={styles.bm_morph_shape}
      itemListClassName={styles.bm_item_list}
      overlayClassName={styles.bm_overlay}>
      <nav className="text-white">
        <ul className={`${styles.sidebar_list} text-2xl`}>
          <li className="cursor-pointer" onClick={() => scrollToSection('services')}>
            Services
          </li>
          <li className="cursor-pointer" onClick={() => scrollToSection('about')}>
            About
          </li>
          <li className="cursor-pointer" onClick={() => scrollToSection('contact')}>
            Contact Me
          </li>
        </ul>
      </nav>
    </Menu>
  );
};

export default MobileNavigation;
