import { processRichText } from '../../../../utils/processRichText';
import Image from '../../../UI/Image/Image';
import styles from './ProjectCard.module.scss';

const ProjectCard = ({ image, title, description }) => {
  return (
    <div className="grid grid-cols-1 grid-rows-3 md:grid-cols-2 md:grid-rows-1 h-full">
      <div className="mt-8 md:mt-0 md:py-0 px-2 lg:px-16 xl:pl-64 flex flex-col justify-center items-center md:items-start order-2 md:order-1 row-span-1">
        <h2 className="text-3xl text-white mb-4 text-center md:text-left">{title}</h2>
        <div
          dangerouslySetInnerHTML={processRichText(description)}
          className="text-white text-2xl text-center md:text-left"
        />
      </div>
      <div className="h-full order-1 md:order-2 row-span-2 md:row-span-1 px-2 sm:px-0">
        <Image src={image.filename} alt={image.alt} className={styles.image} loading="lazy" />
      </div>
    </div>
  );
};

export default ProjectCard;
