import SectionHeadline from '../../../UI/SectionHeadline/SectionHeadline';
import ProjectCardsContainer from '../ProjectCardsContainer/ProjectCardsContainer';
import { storyblokEditable } from '@storyblok/react';

const MyPortfolio = ({ blok }) => {
  return (
    <div
      {...storyblokEditable(blok)}
      id="portfolio"
      data-aos="fade-right"
      data-aos-once="true"
      className="mb-32 md:mb-48">
      <div className="mb-16">
        <SectionHeadline title={blok.title} subTitle={blok.subTitle} lineHeight="4" />
      </div>
      <ProjectCardsContainer portfolioCards={blok.portfolioCards} />
    </div>
  );
};

export default MyPortfolio;
