import ServiceCard from '../ServiceCard/ServiceCard';

const ServiceCardsContainer = ({ servicesCards }) => {
  return (
    <div className="flex justify-center flex-wrap gap-x-6 gap-y-6 lg:gap-x-24">
      {servicesCards.map((card) => {
        return (
          <div data-aos="fade-right" data-aos-once="true" key={card._uid}>
            <ServiceCard Icon={card.icon} title={card.title} description={card.description} />
          </div>
        );
      })}
    </div>
  );
};

export default ServiceCardsContainer;
