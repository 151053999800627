import RootLayout from './RootLayout';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

const Home = ({ blok }) => {
  return (
    <RootLayout>
      <main {...storyblokEditable(blok)}>
        {blok.body
          ? blok.body.map((blok) => <StoryblokComponent blok={blok} key={blok._uid} />)
          : null}
      </main>
    </RootLayout>
  );
};

export default Home;
