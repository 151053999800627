import { useCallback, useEffect, useState } from 'react';
import { sendMessage } from '../service';

export const useSendMessage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const send = useCallback(
    async ({ name, email, message }) => {
      try {
        setLoading(true);
        await sendMessage({ name, email, message }).then(() => {
          setSuccess(true);
          setLoading(false);
        });
      } catch (error) {
        setError(true);
        setLoading(false);
        setSuccess(false);
      }
    },
    [loading, error, success]
  );

  useEffect(() => {
    if (success) {
      const timeout = setTimeout(() => {
        setSuccess(false);
      }, 7000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [success]);

  return { send, loading, error, success };
};
