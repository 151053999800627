import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { storyblokInit, apiPlugin } from '@storyblok/react';
import Home from './pages/Home';
import MyPresentation from './components/Sections/Presentation/MyPresentation/MyPresentation';
import MyServices from './components/Sections/Services/MyServices/MyServices';
import MyPortfolio from './components/Sections/Portfolio/MyPortfolio/MyPortfolio';
import About from './components/Sections/About/MyAbout/About';
import Contact from './components/Sections/Contact/Contact/Contact';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

storyblokInit({
  accessToken: '0vC3s6Mu68vQyGp7KCzbjAtt',
  use: [apiPlugin],
  components: {
    page: Home,
    myPresentation: MyPresentation,
    myServices: MyServices,
    myPortfolio: MyPortfolio,
    about: About,
    contact: Contact
  }
});

const firebaseConfig = {
  apiKey: 'AIzaSyAI8gEosFZdVs_DGrk8PKMT9Td79Pw9i00',
  authDomain: 'pgdeveloper-net-57c7b.firebaseapp.com',
  projectId: 'pgdeveloper-net-57c7b',
  storageBucket: 'pgdeveloper-net-57c7b.appspot.com',
  messagingSenderId: '900056049737',
  appId: '1:900056049737:web:d5b7f9dbb18f50a078f798',
  measurementId: 'G-MPGFY93BQH'
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);
