import { storyblokEditable } from '@storyblok/react';
import Timeline from '../Timeline/Timeline';
import SectionHeadline from '../../../UI/SectionHeadline/SectionHeadline';
import Description from '../Description/Description';
import Statistics from '../Statistics/Statistics';
import { ReactComponent as ReactSVG } from '../../../../assets/images/react.svg';
import { ReactComponent as VueSVG } from '../../../../assets/images/vue.svg';

const About = ({ blok }) => {
  const icons = [ReactSVG, ReactSVG, VueSVG];

  return (
    <div {...storyblokEditable(blok)} id="about" className="mb-32 md:mb-48">
      <div data-aos="fade-right" data-aos-once="true">
        <div className="mb-16">
          <SectionHeadline title={blok.title} subTitle={blok.subTitle} lineHeight="4" />
        </div>
        <div className="flex justify-center mb-16">
          <div className="w-11/12 md:w-full lg:w-3/4 xl:w-2/4">
            <Statistics statisticsItems={blok.statistics} />
          </div>
        </div>
      </div>

      <div
        data-aos="fade-right"
        data-aos-once="true"
        className="flex justify-center w-11/12 md:w-full lg:w-3/4 xl:w-2/4 mb-16 mx-auto">
        <Description text={blok.description} />
      </div>

      <div data-aos="fade-right" data-aos-once="true" className="w-full lg:w-4/5 mx-auto">
        <Timeline
          timeline={blok.timeline.map((item, index) => {
            return { icon: icons[index], ...item };
          })}
        />
      </div>
    </div>
  );
};

export default About;
