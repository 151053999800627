import SectionHeadline from '../../../UI/SectionHeadline/SectionHeadline';
import { storyblokEditable } from '@storyblok/react';
import ContactForm from '../ContactForm/ContactForm';
import { object, string } from 'yup';
import { useState } from 'react';
import update from 'immutability-helper';
import { useSendMessage } from '../../../../hooks/useSendMessage';

const formSchema = object().shape({
  name: string().required(),
  email: string().email().required(),
  message: string().required()
});

const Contact = ({ blok }) => {
  const { send, loading, error, success } = useSendMessage();

  const [values, setValues] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [errors, setErrors] = useState({});

  const fieldChange = (name, value) => {
    setValues((prevState) =>
      update(prevState, {
        [name]: {
          $set: value
        }
      })
    );
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const isFormValid = await formSchema.isValid(values, { abortEarly: false });

    if (isFormValid) {
      await send({ name: values.name, email: values.email, message: values.message });
      setValues((prevState) =>
        update(prevState, {
          $set: {
            name: '',
            email: '',
            message: ''
          }
        })
      );
      setErrors((prevState) =>
        update(prevState, {
          $set: {}
        })
      );
    } else {
      formSchema.validate(values, { abortEarly: false }).catch((error) => {
        const errors = error.inner.reduce((acc, error) => {
          return {
            ...acc,
            [error.path]: error.message
          };
        }, {});

        setErrors((prevState) =>
          update(prevState, {
            $set: errors
          })
        );
      });
    }
  };

  return (
    <div
      {...storyblokEditable(blok)}
      id="contact"
      data-aos="fade-right"
      data-aos-once="true"
      className="mb-8">
      <div className="mb-16">
        <SectionHeadline title={blok.title} subTitle={blok.subTitle} lineHeight="4" />
      </div>
      <div className="lg:px-32 xl:px-64 w-11/12 sm:w-full mx-auto">
        <ContactForm
          values={values}
          errors={errors}
          onTextFieldChange={fieldChange}
          onSubmit={onSubmit}
          loading={loading}
          submitError={error}
          success={success}
        />
      </div>
    </div>
  );
};

export default Contact;
