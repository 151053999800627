import styles from './ServiceCard.module.scss';

const ServiceCard = ({ Icon, title, description }) => {
  return (
    <div className={`${styles.card} text-white px-8 py-10 relative`}>
      <div className={`${styles.circle} rounded-full absolute`} />
      <Icon className="text-4xl mb-4 z-10 relative" size="40" />
      <h2 className="text-2xl mb-4">{title}</h2>
      <p className="text-2xl">{description}</p>
    </div>
  );
};

export default ServiceCard;
