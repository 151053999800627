import styles from './ContactButton.module.scss';

const ContactButton = ({ onClick }) => {
  return (
    <div className="relative" onClick={onClick}>
      <div className={`${styles.button_container} flex absolute`}>
        <button className={`${styles.contact_button} rounded-full font-bold text-2xl  z-10`}>
          Let&apos;s Talk
        </button>
      </div>
    </div>
  );
};

export default ContactButton;
