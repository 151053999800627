import styles from './Teaser.module.scss';

const Teaser = ({ headline, title }) => {
  return (
    <div>
      <h1
        className={`font-bold text-4xl sm:text-6xl md:text-6xl lg:text-8xl text-center mb-5 ${styles.headline}`}>
        {headline}
      </h1>
      <h2 className="text-center text-3xl text-white uppercase">{title}</h2>
    </div>
  );
};

export default Teaser;
