import StoryblokClient from 'storyblok-js-client';

const Storyblok = new StoryblokClient({
  accessToken: '0vC3s6Mu68vQyGp7KCzbjAtt'
});

export const processRichText = (storyblokHTML) => {
  return {
    __html: Storyblok.richTextResolver.render(storyblokHTML)
  };
};
