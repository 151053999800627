import DesktopNavigation from '../components/UI/DesktopNavigation/DesktopNavigation';
import Footer from '../components/Sections/Footer/Footer';
import MobileNavigation from '../components/UI/MobileNavigation/MobileNavigation';

const RootLayout = ({ children }) => {
  return (
    <>
      <div className="hidden sm:block">
        <DesktopNavigation />
      </div>
      <div className="sm:hidden">
        <MobileNavigation />
      </div>
      <main className="container mx-auto">{children}</main>
      <Footer />
    </>
  );
};

export default RootLayout;
