import styles from './Logo.module.scss';

const Logo = () => {
  return (
    <div className="flex font-medium gap-4 items-center relative">
      <div
        className={`rounded-full h-10 w-10 flex items-center justify-center z-10 ${styles.logo}`}>
        pg
      </div>
    </div>
  );
};

export default Logo;
