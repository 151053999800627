import ContactButton from '../ContactButton/ContactButton';
import SocialMedia from '../SocialMedia/SocialMedia';
import Teaser from '../Teaser/Teaser';
import styles from './MyPresentation.module.scss';
import { storyblokEditable } from '@storyblok/react';
import Image from '../../../UI/Image/Image';
import { scrollTo } from '../../../../utils/scrollTo';

const MyPresentation = ({ blok }) => {
  return (
    <div {...storyblokEditable(blok)} id="presentation" className="mb-32 md:mb-48">
      <div
        className={`${styles.main_presentation} mx-auto flex justify-center relative items-center flex-col sm:flex-row mb-0`}>
        <div className={`${styles.links} hidden lg:block lg:absolute`}>
          <SocialMedia
            direction="column"
            linkedInLink={blok.linkedIn.url}
            githubLink={blok.github.url}
          />
        </div>
        <div className="mb-4 mt-8 md:mt-0 sm:mb-0">
          <Image
            src={blok.image.filename}
            alt="author"
            loading="eager"
            className={`${styles.image} object-cover m-auto`}
          />
        </div>
        <ContactButton onClick={() => scrollTo('contact')} />
      </div>
      <div className="mb-4 md:mb-0">
        <Teaser headline={blok.headline} title={blok.title} />
      </div>

      <div className="lg:hidden">
        <SocialMedia
          direction="row"
          linkedInLink={blok.linkedIn.url}
          githubLink={blok.github.url}
        />
      </div>
    </div>
  );
};

export default MyPresentation;
