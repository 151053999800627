import Logo from '../Logo/Logo';
import styles from './AuthorLogo.module.scss';

const AuthorLogo = () => {
  return (
    <div className="flex font-medium gap-4 items-center relative">
      <Logo />
      <h1 className={`text-2xl ${styles.author_name}`}>przemek gorecki</h1>
    </div>
  );
};
export default AuthorLogo;
