import SectionHeadline from '../../../UI/SectionHeadline/SectionHeadline';
import ServiceCardsContainer from '../ServiceCardsContainer/ServiceCardsContainer';
import { storyblokEditable } from '@storyblok/react';
import { UilDesktop, UilCodeBranch } from '@iconscout/react-unicons';

const MyServices = ({ blok }) => {
  const icons = [UilDesktop, UilCodeBranch];

  return (
    <div {...storyblokEditable(blok)} id="services" className="mb-32 md:mb-48">
      <div className="mb-16">
        <SectionHeadline title={blok.title} subTitle={blok.subTitle} lineHeight="4" />
      </div>
      <ServiceCardsContainer
        servicesCards={blok.servicesCards.map((card, index) => {
          return { icon: icons[index], ...card };
        })}
      />
    </div>
  );
};

export default MyServices;
